/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'

const DashboardWrapper: FC = () => {
  return (
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-xxl-12'>
        <EngageWidget10 className='h-sm-100' />
      </div>
    </div>
  )
}

export {DashboardWrapper}
