/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const {isAuthorized} = useAuth()
  const intl = useIntl()

  return (
    <div style={{overflow: "auto"}}>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={'Dashboard'}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Access</span>
        </div>
      </div>
      <>
          {isAuthorized('read_role') && (
              <SidebarMenuItem
                  to='/role'
                  icon='security-user'
                  title={'Role'}
                  fontIcon='bi-app-indicator'
              />
          )}

          {isAuthorized('read_user') && (
              <SidebarMenuItem to='/user' icon='user' title={'User'} fontIcon='bi-app-indicator' />
          )}
      </>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Location</span>
        </div>
      </div>
      <>
          {isAuthorized('read_region') && (
              <SidebarMenuItem
                  to='/region'
                  icon='abstract-33'
                  title={'Region'}
                  fontIcon='bi-app-indicator'
              />
          )}
          {isAuthorized('read_zone') && (
              <SidebarMenuItem to='/zone' icon='share' title={'Zone'} fontIcon='bi-app-indicator' />
          )}
          {isAuthorized('read_city') && (
              <SidebarMenuItem
                  to='/city'
                  icon='notification-circle'
                  title={'City'}
                  fontIcon='bi-app-indicator'
              />
          )}
      </>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Vehicle</span>
        </div>
      </div>
      <>
          {isAuthorized('read_association') && (
              <SidebarMenuItem
                  to='/association'
                  icon='data'
                  title={'Association'}
                  fontIcon='bi-app-indicator'
              />
          )}
          {isAuthorized('read_vehicle') && (
              <SidebarMenuItem to='/vehicle' icon='car' title={'Vehicle'} fontIcon='bi-app-indicator' />
          )}
      </>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Variables</span>
        </div>
      </div>
      <>
          {isAuthorized('read_level') && (
              <SidebarMenuItem to='/level' icon='ranking' title={'Level'} fontIcon='bi-app-indicator' />
          )}
          {isAuthorized('read_plate_type') && (
              <SidebarMenuItem
                  to='/plate-type'
                  icon='parcel-tracking'
                  title={'Plate Type'}
                  fontIcon='bi-app-indicator'
              />
          )}
          {isAuthorized('read_seat_number') && (
              <SidebarMenuItem
                  to='/seat-number'
                  icon='burger-menu-3'
                  title={'Seat Number'}
                  fontIcon='bi-app-indicator'
              />
          )}
      </>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Routes</span>
        </div>
      </div>
      <>
        {isAuthorized('read_route') && (
          <SidebarMenuItem to='/route' icon='route' title={'Route'} fontIcon='bi-app-indicator' />
        )}

        {isAuthorized('read_station') && (
          <SidebarMenuItem
            to='/station'
            icon='lots-shopping'
            title={'Station'}
            fontIcon='bi-app-indicator'
          />
        )}
      </>
    </div>
  )
}

export {SidebarMenuMain}
