import {Navigate} from 'react-router-dom'
import {useAuth} from '../modules/auth'

type Props = {privilege: string; content: any}
export default function RoleProtection(props: Props) {
  const {isAuthorized} = useAuth()

  let privileges = props.privilege.split(',')

  for (let privilege of privileges) {
    if (!isAuthorized(privilege)) {
      return <Navigate to={'/error/403'} />
    }
  }

  return props.content
}
