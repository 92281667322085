import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {User, AuthModel} from '../../../requests/models'
import {ApiResponse, request} from '../../../requests/api'
import {AuthRoutes} from '../../../requests/routes'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: User | undefined
  setCurrentUser: Dispatch<SetStateAction<User | undefined>>
  setUserPrivileges: Dispatch<SetStateAction<Set<string>>>
  isAuthorized: (privilege: string) => boolean
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  setUserPrivileges: () => {},
  isAuthorized: (privilege: string) => false,
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<User | undefined>()
  const [userPrivileges, setUserPrivileges] = useState<Set<string>>(new Set())
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  function setPrivileges() {
    if (currentUser && currentUser?.role) {
      const accessRuleSet: Set<string> = new Set()
      currentUser?.role?.access_rules.map((rule: string) =>
        accessRuleSet.add(rule.toLowerCase().trim())
      )
      setUserPrivileges(accessRuleSet)
    }
  }

  const isAuthorized = (privilege: string): boolean => {
    if (userPrivileges.size === 0) {
      setPrivileges()
    }
    return userPrivileges.has(privilege.toLowerCase().trim())
  }
  return (
    <AuthContext.Provider
      value={{auth, saveAuth, currentUser, setCurrentUser, setUserPrivileges, logout, isAuthorized}}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setUserPrivileges} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await request.get<ApiResponse<User>>(AuthRoutes.BASE)
          if (data) {
            setCurrentUser(data)
            if (data.role) {
              const accessRuleSet: Set<string> = new Set()
              data.role.access_rules.map((rule) => accessRuleSet.add(rule.toLowerCase().trim()))
              setUserPrivileges(accessRuleSet)
            }
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser().catch((error) => console.log(error))
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
