import {
  ErrorResponse,
  FilterOperator,
  FilterType,
  IncludeInputType,
  PaginatedApiResponse,
  QueryParams,
  request,
} from '../requests/api'
import clsx from 'clsx'
import {Pagination} from '../modules/apps/components/ListPagination'
import moment from 'moment/moment'

export type Sortable = {
  state: 'ASC' | 'DESC' | undefined
  key: string
}

export function headerProps(props: {
  className?: string
  key: string
  sortState: Sortable[]
  setSortState: Function
}): any {
  let sortable = props.sortState.find((state) => state.key === props.key)
  return {
    className: clsx(
      props.className,
      `cursor-pointer`,
      `table-sort-${(sortable?.state ?? '').toLowerCase()}`
    ),
    onClick: () => {
      ToggleSortable(
        props.key,
        sortable ? props.sortState : [...props.sortState, {key: props.key, state: undefined}],
        props.setSortState
      )
    },
  }
}

export function ToggleSortable(key: string, sortStates: Sortable[], setSortState: Function) {
  let newSortState = []
  for (let sortState of sortStates) {
    if (sortState.key !== key) {
      newSortState.push(sortState)
    } else {
      if (sortState.state !== 'DESC') {
        newSortState.push({
          key: key,
          state: sortState.state === undefined ? 'ASC' : 'DESC',
        })
      }
    }
  }
  setSortState(newSortState)
}

export function setupParams(props: {
  keys: string[]
  pagination?: Pagination
  searchQuery?: string
  filterQuery?: FilterType[]
  sortState?: Sortable[]
  include?: IncludeInputType[]
  paranoid?: boolean
}): QueryParams {
  let params: QueryParams = {
    offset: 0,
    limit: 10,
  }
  if (props.pagination) {
    params = {
      ...params,
      offset: (props.pagination.page - 1) * (props.pagination.pageSize ?? 10),
      limit: props.pagination.pageSize ?? 10,
    }
  }

  if (props.include) {
    params = {
      ...params,
      include: props.include,
    }
  }

  if (props.paranoid) {
    params = {
      ...params,
      paranoid: props.paranoid,
    }
  }

  if (props.searchQuery) {
    params = {
      ...params,
      search: {keys: props.keys, value: props.searchQuery},
    }
  }
  if (props.filterQuery && props.filterQuery.length > 0) {
    params = {
      ...params,
      filter: props.filterQuery,
    }
  }
  if (props.sortState && props.sortState.length > 0) {
    let state = [
      ...props.sortState
        .filter((sort) => sort.state !== undefined)
        .map((sort) => ({key: sort.key, value: sort.state ?? 'ASC'})),
    ]
    if (state.length > 0) {
      params = {
        ...params,
        order: state,
      }
    }
  }

  console.log(params)

  return params
}

export const jsonToUriParam = (jsonData: any, prefix = ''): string => {
  const params: string[] = []

  for (const key in jsonData) {
    if (jsonData.hasOwnProperty(key)) {
      const value = jsonData[key]
      const paramKey = prefix ? `${prefix}[${key}]` : key

      if (typeof value === 'object') {
        const nestedParams = jsonToUriParam(value, paramKey)
        params.push(nestedParams)
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const nestedKey = `${paramKey}[${index}]`
          if (typeof item === 'object') {
            const nestedParams = jsonToUriParam(item, nestedKey)
            params.push(nestedParams)
          } else {
            const encodedKey = encodeURIComponent(nestedKey)
            const encodedValue = encodeURIComponent(String(item))
            params.push(`${encodedKey}=${encodedValue}`)
          }
        })
      } else {
        const encodedKey = encodeURIComponent(paramKey)
        const encodedValue = encodeURIComponent(String(value))
        params.push(`${encodedKey}=${encodedValue}`)
      }
    }
  }

  return params.join('&')
}

export const filter_AND_parser = (
  filters: FilterType[] | undefined,
  key: string,
  value: any,
  operator: FilterOperator,
  parser?: (value: any) => any
) => {
  if (!filters) {
    filters = []
  }

  if (filters.length > 0) {
    let _filters = []
    let found = false
    for (let filter of filters) {
      if (key === filter.key) {
        if (value) {
          _filters.push({key: key, value: parser ? parser(value) : value, operator: operator})
        }
        found = true
      } else {
        _filters.push(filter)
      }
    }
    if (!found && value) {
      _filters.push({key: key, value: parser ? parser(value) : value, operator: operator})
    }
    filters = _filters
  } else {
    if (value) {
      filters.push({key: key, value: parser ? parser(value) : value, operator: operator})
    }
  }
  return filters
}

export type DateRange = {start: moment.Moment; end: moment.Moment}
export type NumberRange = {start: number; end: number}

export function NumberFindFilterQuery(
  key: string,
  filterQuery?: FilterType[]
): NumberRange | undefined {
  return findFilterQuery<[number, number], NumberRange>(key, filterQuery, (value) => {
    try {
      let start = value[0] ?? undefined
      let end = value[1] ?? undefined
      return start && end ? {start: start, end: end} : undefined
    } catch (e) {
      return undefined
    }
  })
}

export function DateFindFilterQuery(
  key: string,
  filterQuery?: FilterType[]
): DateRange | undefined {
  return findFilterQuery<[string, string], DateRange>(key, filterQuery, (value) => {
    try {
      let start = value[0] ? moment(value[0]) : undefined
      let end = value[1] ? moment(value[1]) : undefined
      return start && end ? {start: start, end: end} : undefined
    } catch (e) {
      return undefined
    }
  })
}

export function findFilterQuery<T, I>(
  key: string,
  filterQuery?: FilterType[],
  parser?: (value: T) => I | undefined
): I | undefined {
  let val: any = filterQuery?.find((q) => q.key === key)?.value
  if (!val) return undefined
  return parser ? parser(val) : val
}

export function parseNumber(num?: number, precision?: number): string {
  return num ? (precision ? num.toPrecision(precision) : num).toLocaleString() : '---'
}
export function trimString(text?: string, count = 5, trail = '...'): string {
  return text ? (text.length > count ? `${text.substring(0, count)}${trail}` : text) : '---'
}

export function asyncPromiseOptions<I, R>(
  route: string,
  params: QueryParams,
  parser: (data: PaginatedApiResponse<I>) => R,
  onError: (err: ErrorResponse) => void,
  rejectObject: any
): Promise<R> {
  return new Promise<R>((resolve, reject) => {
    request
      .get<PaginatedApiResponse<I>>(route, {
        params,
      })
      .then((response) => {
        resolve(parser(response))
      })
      .catch((err: ErrorResponse) => {
        onError(err)
        reject(rejectObject)
      })
  })
}


export function parseIntlValue(str:string, replacement:any){
  return str.replaceAll("*{val}*", replacement)
}
