import React, {createContext, FC, useContext, useState} from 'react'
import {Toast, ToastContainer} from 'react-bootstrap'
import {WithChildren} from '../_metronic/helpers'
import {CSSTransition, TransitionGroup} from 'react-transition-group'

export type ToastType = 'info' | 'success' | 'warning' | 'danger' | undefined

interface ToastContextData {
  showToast: (title: string, message: any, type?: ToastType) => void
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

export const ToastProvider: FC<WithChildren> = ({children}) => {
  const [toasts, setToasts] = useState<{key: string; toast: React.ReactNode}[]>([])

  const showToast = (title: string, message: string | undefined, type: ToastType) => {
    const id = `toast-${Math.random()}`

    const toast = (
      <CSSTransition key={`transition-${id}`} timeout={300} classNames='toast'>
        <Toast
          key={id}
          onClose={() => closeToast(id)}
          delay={3000}
          autohide
          className={`bg-${type}`}
        >
          <Toast.Header className={`bg-${type}`}>
            <strong className='me-auto h5'>{title}</strong>
          </Toast.Header>
          {message && <Toast.Body>{message}</Toast.Body>}
        </Toast>
      </CSSTransition>
    )

    setToasts((prevToasts) => [...prevToasts, {key: id, toast}])
  }

  const closeToast = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toastObj) => toastObj.key !== id))
  }

  return (
    <ToastContext.Provider value={{showToast}}>
      {children}
      <ToastContainer position='top-end' className={'m-5 position-fixed'}>
        <TransitionGroup>{toasts.map((toastObj) => toastObj.toast)}</TransitionGroup>
      </ToastContainer>
    </ToastContext.Provider>
  )
}

export function useToast(): ToastContextData {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider.')
  }

  return context
}
