export const AuthRoutes = {
  BASE: '/auth',
  LOGIN: '/auth/login',
}

export const RoleRoutes = {
  BASE: '/roles',
}

export const SeatNumberRoutes = {
  BASE: '/seat_numbers',
}

export const PlateTypeRoutes = {
  BASE: '/plate_types',
}

export const VehicleRoutes = {
  BASE: '/vehicles',
}

export const RouteRoutes = {
  BASE: '/routes',
}

export const StationRoutes = {
  BASE: '/stations',
}

export const AccessRuleRoutes = {
  BASE: '/access_rules',
}

export const UserRoutes = {
  BASE: '/users',
}

export const LevelRoutes = {
  BASE: '/levels',
}

export const AssociationRoutes = {
  BASE: '/associations',
}

export const RegionRoutes = {
  BASE: '/regions',
}

export const ZoneRoutes = {
  BASE: '/zones',
}

export const CityRoutes = {
  BASE: '/cities',
}
