import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {removeAuth, setAuthError, setupAxios} from '../modules/auth'
import {jsonToUriParam} from '../utilities/helpers'

const API_URL = `${process.env.REACT_APP_API_URL}`

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS' | 'HEAD'
export type FilterOperator =
  | '='
  | '!='
  | 'ilike'
  | 'like'
  | 'between'
  | '>'
  | '>='
  | '<'
  | '<='
  | 'in'
  | 'or'
  | 'and'

export interface FilterType {
  key: string
  operator: FilterOperator
  value: string | string[] | FilterType[]
}

export interface SearchType {
  keys: string[]
  value: string
}

export interface OrderType {
  key: string
  value: 'ASC' | 'DESC'
  literal?: boolean
}

export interface IncludeInputType {
  model: string
  alias?: string
  filter?: FilterType[]
}

export type QueryParams = {
  offset?: number
  limit?: number
  filter?: FilterType[]
  search?: SearchType
  order?: OrderType[]
  include?: IncludeInputType[]
  paranoid?:boolean
}

export interface RequestOptions {
  data?: any
  params?: QueryParams
  headers?: Record<string, string>
  config?: AxiosRequestConfig
}

export interface FileUploadOptions extends RequestOptions {
  fileField: string
  files: File | File[]
}

interface InputValidationError {
  message?: string
  context?: {label: string; key: string}
  path?: string[]
  type?: string
}

interface RequestErrorData {
  timestamp?: Date
  errors?: string[]
  details?: InputValidationError[]
}

interface Response {
  status: number
  message?: string
  error?: RequestErrorData
}

export interface ApiResponse<T> extends Response {
  data: T
}

export interface PaginatedApiResponse<T> extends Response {
  data: {
    count: number
    rows: T[]
  }
}

const api: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

setupAxios(api)

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    if (error.response?.status === 401 && localStorage.getItem('auth-react-v')) {
      removeAuth();
      setAuthError({key: "Authentication Error", value: "Token has expired! Please sign in again"})
      window.location.reload()
    }
    return Promise.reject(parseErrors(error))
  }
)

async function axiosRequest<T = any>(
  method: Method,
  url: string,
  options: RequestOptions = {}
): Promise<AxiosResponse<T>> {
  options.config = {...options.config, ...options.headers}

  const requestConfig: AxiosRequestConfig = {
    method,
    url,
    ...options.config,
    params: options.params ? {query: jsonToUriParam(options.params)} : {},
    data: options.data,
  }

  return api(requestConfig)
}

const get = async function <T>(endpoint: string, requestOptions: RequestOptions = {}): Promise<T> {
  const response = await axiosRequest<T>('GET', endpoint, requestOptions)
  return response.data
}

const post = async function <T>(endpoint: string, requestOptions: RequestOptions = {}): Promise<T> {
  const response = await axiosRequest<T>('POST', endpoint, requestOptions)
  return response.data
}

const put = async function <T>(endpoint: string, requestOptions: RequestOptions = {}): Promise<T> {
  const response = await axiosRequest<T>('PUT', endpoint, requestOptions)
  return response.data
}

const patch = async function <T>(
  endpoint: string,
  requestOptions: RequestOptions = {}
): Promise<T> {
  const response = await axiosRequest<T>('PATCH', endpoint, requestOptions)
  return response.data
}

const del = async function <T>(endpoint: string, requestOptions: RequestOptions = {}): Promise<T> {
  const response = await axiosRequest<T>('DELETE', endpoint, requestOptions)
  return response.data
}

const uploadFile = async function <T>(
  endpoint: string,
  fileUploadOptions: FileUploadOptions,
  onProgress?: (progressEvent: ProgressEvent) => void
): Promise<T> {
  const formData = new FormData()

  if (Array.isArray(fileUploadOptions.files)) {
    fileUploadOptions.files.forEach((f, index) => {
      formData.append(`${fileUploadOptions.fileField}[${index}]`, f)
    })
  } else {
    formData.append(fileUploadOptions.fileField, fileUploadOptions.files)
  }
  if (fileUploadOptions.data) {
    for (let key in fileUploadOptions.data) {
      formData.append(key, fileUploadOptions.data[key])
    }
  }

  const config = {
    onUploadProgress: onProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  fileUploadOptions.config = {...fileUploadOptions.config, ...config}
  fileUploadOptions.data = formData

  const response = await axiosRequest<T>('POST', endpoint, fileUploadOptions)
  return response.data
}

export const _request = function <T>(
  method: Method,
  endpoint: string,
  requestOptions: RequestOptions = {}
): Promise<T> {
  switch (method) {
    case 'GET':
      return get<T>(endpoint, requestOptions)
    case 'PUT':
      return put<T>(endpoint, requestOptions)
    case 'PATCH':
      return patch<T>(endpoint, requestOptions)
    case 'POST':
      return post<T>(endpoint, requestOptions)
    case 'DELETE':
      return del<T>(endpoint, requestOptions)
    default:
      return get<T>(endpoint, requestOptions)
  }
}

export const request = {
  get,
  post,
  put,
  patch,
  del,
  uploadFile,
}

export type ErrorResponse = {
  status: number
  data: any
  message: string
  errors: {key: string; message: string}[]
}

export function parseErrors(axiosError: AxiosError<ApiResponse<any>>): ErrorResponse {
  const errorsMap: {key: string; message: string}[] = []
  let result: ErrorResponse | null = null
  if (axiosError) {
    const response = axiosError.response
    if (response) {
      const responseData = response.data
      if (responseData && responseData.error) {
        const error = responseData.error
        if (error.timestamp) {
          for (let err of error.errors ?? []) {
            errorsMap.push({
              key: '',
              message: err,
            })
          }
        } else {
          let details = error.details
          for (let err of details ?? []) {
            if (err.message && err.path) {
              errorsMap.push({
                key: err?.path[0] ?? '',
                message: err.message,
              })
            }
          }
        }
      }
      if (errorsMap.length === 0) {
        errorsMap.push({
          key: '',
          message: 'Unable to perform the requested action',
        })
      }
      result = {
        status: responseData.status,
        data: responseData.data,
        message: responseData.message ?? 'Error',
        errors: errorsMap,
      }
    } else {
      errorsMap.push({
        key: '',
        message: 'Seems like the server is down. Please try again later!',
      })
    }
  }

  return (
    result ?? {
      status: 500,
      data: null,
      message: 'Connection Error',
      errors: errorsMap,
    }
  )
}
